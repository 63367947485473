import React from 'react';
import { Resource } from 'react-admin';
import { get } from 'lodash';
import { ROLE_ADMIN, ROLE_RESTAURATEUR } from '../providers/authProvider';
import * as user from '../user';
import * as restaurateur from '../restaurateur';
import * as manager from '../manager';
import * as fridge from '../fridge';
import * as fridgeMedia from '../fridge_media';
import * as product from '../product';
import * as deliverer from '../deliverer';
import * as invoice from '../invoice';
import * as order from '../order';
import * as customer from '../customer';
import * as saleOverview from '../sale_overview';
import * as dailySalesReport from '../daily_sales_report';

export default (permissions) => {
  const roles = get(permissions, 'roles', []);
  switch (true) {
    case roles.includes(ROLE_ADMIN): {
      return [
        <Resource
          key="users"
          name="users"
          list={user.list}
          show={user.show}
          edit={user.edit}
          icon={user.icon}
        />,
        <Resource
          key="restaurateurs"
          name="restaurateurs"
          list={restaurateur.list}
          show={restaurateur.show}
          edit={restaurateur.edit}
          create={restaurateur.create}
          icon={restaurateur.icon}
        />,
        <Resource
          key="managers"
          name="managers"
          list={manager.list}
          show={manager.show}
          edit={manager.edit}
          create={manager.create}
          icon={manager.icon}
        />,
        <Resource key="metrics" name="metrics" />,
        <Resource
          key="fridges"
          name="fridges"
          list={fridge.list}
          show={fridge.show}
          edit={fridge.edit}
          create={fridge.create}
          icon={fridge.icon}
        />,
        <Resource key="product_overviews" name="product_overviews" />,
        <Resource
          key="products"
          name="products"
          show={product.show}
          icon={product.icon}
        />,
        <Resource key="sale_overviews" name="sale_overviews" />,
        <Resource
          key="deliverers"
          name="deliverers"
          list={deliverer.list}
          show={deliverer.show}
          edit={deliverer.edit}
          create={deliverer.create}
          icon={deliverer.icon}
        />,
        <Resource key="countries" name="countries" />,
        <Resource
          key="invoices"
          name="invoices"
          list={invoice.list}
          show={invoice.show}
          edit={invoice.edit}
          icon={invoice.icon}
        />,
        <Resource name="orders" />,
        <Resource key="temperature_readings" name="temperature_readings" />,
        <Resource
          key="fridge_media"
          name="fridge_media"
          edit={fridgeMedia.edit}
          create={fridgeMedia.create}
          show={fridgeMedia.show}
        />,
      ];
    }
    case roles.includes(ROLE_RESTAURATEUR): {
      return [
        <Resource
          key="orders"
          name="orders"
          list={order.list}
          show={order.show}
          icon={order.icon}
        />,
        <Resource
          key="customers"
          name="customers"
          list={customer.list}
          show={customer.show}
          icon={customer.icon}
        />,
        <Resource key="order_lines" name="order_lines" />,
        <Resource key="metrics" name="metrics" />,
        <Resource key="users" name="users" show={user.show} />,
        <Resource key="product_overviews" name="product_overviews" />,
        <Resource
          key="products"
          name="products"
          show={product.show}
          list={product.list}
          create={product.create}
          edit={product.edit}
          icon={product.icon}
        />,
        <Resource
          key="sale_overviews"
          name="sale_overviews"
          list={saleOverview.list}
          icon={saleOverview.icon}
        />,
        <Resource
          key="fridges"
          name="fridges"
          list={fridge.list}
          show={fridge.show}
          edit={fridge.edit}
          icon={fridge.icon}
        />,
        <Resource
          key="deliverers"
          name="deliverers"
          list={deliverer.list}
          show={deliverer.show}
          edit={deliverer.edit}
          create={deliverer.create}
          icon={deliverer.icon}
        />,
        <Resource
          key="daily_sales_reports"
          name="daily_sales_reports"
          list={dailySalesReport.list}
          icon={dailySalesReport.icon}
        />,
        <Resource
          key="invoices"
          name="invoices"
          list={invoice.list}
          show={invoice.show}
          icon={invoice.icon}
        />,
        <Resource key="temperature_readings" name="temperature_readings" />,
        <Resource
          key="fridge_media"
          name="fridge_media"
          edit={fridgeMedia.edit}
          create={fridgeMedia.create}
          show={fridgeMedia.show}
        />,
        <Resource key="product_prices" name="product_prices" />,
      ];
    }
    default: {
      return null;
    }
  }
};
