import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceManyField,
  Pagination,
  translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import inputs from './inputs';
import { ROLE_ADMIN } from '../providers/authProvider';
import EditablePriceGrid from './EditablePriceGrid';

const ProductEdit = (props) => {
  const { permissions, translate, ...rest } = props;

  return (
    <Edit {...rest}>
      <SimpleForm redirect="show">
        {inputs}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            key="piElectroniqueArticleRef"
            source="piElectroniqueArticleRef"
            parse={(value) => value || null}
          />
        )}
        <ReferenceManyField
          reference="product_prices"
          target="product.id"
          linkType="show"
          addLabel={false}
          source="prices"
        >
          <EditablePriceGrid source="price" editable={true} />
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};

ProductEdit.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

ProductEdit.defaultProps = {
  permissions: null,
};

export default withTranslate(ProductEdit);
