import React, { useState } from 'react';
import {
  List, Datagrid, ReferenceField, ShowButton, Filter, DateInput, downloadCSV,
  translate as withTranslate, TopToolbar, LinearProgress, Button, useNotify,
} from 'react-admin';
import Papaparse from 'papaparse';
import * as PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import fields from './fields';
import { EmailOrNfcField } from '../user/fields';
import { entrypoint } from '../config';

const OrderListFilter = withTranslate(({ translate, ...rest }) => [
  <Filter {...rest}>
    <DateInput label={translate('resources.orders.filters.from')} source="date[after]" alwaysOn />
    <DateInput label={translate('resources.orders.filters.to')} source="date[before]" alwaysOn />
  </Filter>,
]);

const CustomExportButton = withTranslate(({ translate, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const customExport = async (filters) => {
    if (loading) return;
    let url = `${entrypoint}/orders/export`;
    if (filters.date) {
      const params = [];
      if (filters.date.before) params.push(`date[before]=${filters.date.before}`);
      if (filters.date.after) params.push(`date[after]=${filters.date.after}`);
      url += `?${params.join('&')}`;
    }
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    setLoading(true);
    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const blob = await res.blob();
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    } catch (e) {
      console.log(e);
      notify('ra.notification.http_error', { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button color="primary" icon={DownloadIcon} label={loading ? '' : translate('ra.action.download')} onClick={() => customExport(rest.filterValues)}>
      {loading ? <LinearProgress /> : <DownloadIcon />}
    </Button>
  );
});

const OrderListToolbar = (props) => (
  <TopToolbar>
    <CustomExportButton {...props} />
  </TopToolbar>
);

const exporter = async (records, fetchRelatedRecords) => {
  const clients = await fetchRelatedRecords(records, 'client', 'users');
  const data = records.reduce((agg, record) => [
    ...agg,
    ...record.lines.map((line) => ({
      ...record,
      client: clients[record.client].email,
      total: parseFloat(record.total.toFixed(2)),
      ...line,
    })),
  ], []);
  const csv = Papaparse.unparse({
    data,
    fields: ['fridgeSlug', 'client', 'date', 'total', 'productName', 'quantity', 'price', 'tax'],
  });
  downloadCSV(csv, 'orders');
};

const OrderDataGrid = ({ sortable, ...props }) => (
  <Datagrid rowClick="show" {...props}>
    <ReferenceField
      label="client"
      source="client['@id']"
      reference="users"
      link={(record, reference) => (record.type === 'nfc_buy' ? false : `${reference}/${encodeURIComponent(record.client['@id'])}/show`)}
      sortBy="client.email"
      sortable={sortable}
    >
      <EmailOrNfcField />
    </ReferenceField>
    {fields(sortable)}
    <ShowButton />
  </Datagrid>
);

OrderDataGrid.propTypes = {
  sortable: PropTypes.bool,
};

OrderDataGrid.defaultProps = {
  sortable: true,
};

const OrderList = (props) => (
  <List bulkActionButtons={false} filters={<OrderListFilter />} actions={<OrderListToolbar />} exporter={exporter} {...props}>
    <OrderDataGrid />
  </List>
);

export const TodayOrderList = (props) => (
  <List
    pagination={null}
    filters={null}
    perPage={1000}
    filter={{
      pagination: 'false',
      'date[after]': `${(new Date()).toISOString().split('T')[0]}T00:00:00`,
      'date[before]': `${(new Date()).toISOString().split('T')[0]}T23:59:59`,
    }}
    bulkActionButtons={false}
    exporter={false}
    {...props}
  >
    <OrderDataGrid sortable={false} />
  </List>
);

export default OrderList;
