import React from 'react';
import { Show, SimpleShowLayout, ReferenceManyField } from 'react-admin';
import * as PropTypes from 'prop-types';
import fields from './fields';
import EditablePriceGrid from './EditablePriceGrid';

const ProductShow = (props) => {
  const { permissions } = props;

  return (
    <Show {...props}>
      <SimpleShowLayout>
        {fields(permissions)}
        <ReferenceManyField
          reference="product_prices"
          target="product.id"
          linkType="show"
          addLabel={false}
          source="prices"
        >
          <EditablePriceGrid source="price" editable={false} />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

ProductShow.propTypes = {
  permissions: PropTypes.shape({
    manager: PropTypes.shape({
      restaurateur: PropTypes.shape({
        piElectroniqueSupport: PropTypes.bool.isRequired,
      }),
    }),
  }),
};

ProductShow.defaultProps = {
  permissions: null,
};

export default ProductShow;
