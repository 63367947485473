import React, { useEffect, useState } from 'react';
import {
  useDataProvider,
  useListController,
  useRecordContext,
  useGetList,
  translate as withTranslate,
} from 'react-admin';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Select, MenuItem, TextField } from '@material-ui/core';

const ParamsField = (props) => {
  const disabled = props.disabled;
  const params = props.params;
  const translate = props.translate;
  const handleCategoryChange = props.handleCategoryChange;
  const handleFilterChange = props.handleFilterChange;
  const saveFilterChange = props.saveFilterChange;
  const fridges = props.fridges;

  const [hostnameError, setHostnameError] = useState(false);
  const currentValue = params.row.dnsNamePriceActivated
    ? params.row.dnsName
    : params.row.paymentMethodPriceActivated
    ? params.row.paymentMethod
    : params.row.fridgePriceActivated
    ? params.row.fridge
    : '';
  return params.row.dnsNamePriceActivated ? (
    <TextField
      disabled={disabled}
      value={currentValue}
      placeholder={'ex. eatmachine.com'}
      onChange={(event) => {
        handleFilterChange(params.row, event.target.value);
      }}
      onBlur={(event) => {
        const validateRegex =
          /^(?=.{1,253}$)([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
        if (validateRegex.test(event.target.value)) {
          setHostnameError(false);
          saveFilterChange(params.row, event.target.value);
        } else {
          setHostnameError(true);
        }
      }}
      error={hostnameError}
      helperText={
        hostnameError
          ? translate('resources.products.fields.hostnameError')
          : ''
      }
    />
  ) : params.row.paymentMethodPriceActivated ? (
    <Select
      disabled={disabled}
      value={currentValue}
      onChange={(evt) => {
        const value = evt.target.value;
        const currentRow = params.row;
        handleFilterChange(currentRow, value);
        saveFilterChange(currentRow, value);
      }}
      fullWidth
    >
      <MenuItem key={'paygreen'} value={'paygreen'}>
        Paygreen
      </MenuItem>
      <MenuItem key={'edenred'} value={'edenred'}>
        Edenred
      </MenuItem>
      <MenuItem key={'pielectronique'} value={'pielectronique'}>
        PIElectronique
      </MenuItem>
      <MenuItem key={'jes'} value={'jes'}>
        JES
      </MenuItem>
    </Select>
  ) : params.row.fridgePriceActivated ? (
    <Select
      disabled={disabled}
      value={currentValue['@id']}
      onChange={(evt) => {
        const value = evt.target.value;
        const currentRow = params.row;
        const currentFridge = fridges.find((fridge) => fridge.id === value);

        handleFilterChange(currentRow, currentFridge);
        saveFilterChange(currentRow, value);
      }}
      fullWidth
    >
      {fridges.map((fridge) => (
        <MenuItem key={fridge.id} value={fridge.id}>
          {fridge.name}
        </MenuItem>
      ))}
    </Select>
  ) : null;
};

const EditablePriceGrid = (props) => {
  const editable = props.editable;
  const translate = props.translate;
  const record = useRecordContext();
  const { data, ids, loaded } = useListController({
    ...props,
    filter: { 'product.id': record?.id },
  });
  const dataProvider = useDataProvider();
  const [displayTab, setDisplayTab] = useState(false);

  const categories = [
    { id: 'dns', name: translate('resources.products.fields.hostname') },
    {
      id: 'paymentMethod',
      name: translate('resources.products.fields.paymentMethod'),
    },
    { id: 'fridge', name: translate('resources.products.fields.fridge') },
  ];

  const { data: dataFridge } = useGetList('fridges', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'published_at', order: 'DESC' },
    filter: { 'product.id': record?.id },
  });
  const fridges = Object.keys(dataFridge).map((key) => {
    return dataFridge[key];
  });

  const newLine = {
    '@type': 'ProductPrice',
    id: null,
    product: record.id,
    fridge: null,
    fridgePriceActivated: false,
    fridgePriceValue: null,
    dnsName: null,
    dnsNamePriceActivated: false,
    dnsNamePriceValue: null,
    paymentMethod: null,
    paymentMethodPriceActivated: false,
    paymentMethodPriceValue: null,
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (loaded) {
      setRows(ids.map((id) => ({ id, ...data[id] })));
    }
  }, [loaded, ids, data]);

  const handleCategoryChange = (updatedRow, value) => {
    const newRow = { ...newLine };
    newRow.id = updatedRow.id;
    newRow.product = record.id;
    if (value === 'dns') {
      newRow.dnsNamePriceActivated = true;
      newRow.dnsName = updatedRow.dnsName || '';
      newRow.dnsNamePriceValue = updatedRow.dnsNamePriceValue || 0;
    } else if (value === 'paymentMethod') {
      newRow.paymentMethodPriceActivated = true;
      newRow.paymentMethod = updatedRow.paymentMethod || '';
      newRow.paymentMethodPriceValue = updatedRow.paymentMethodPriceValue || 0;
    } else if (value === 'fridge') {
      newRow.fridgePriceActivated = true;
      newRow.fridge = updatedRow.fridge || '';
      newRow.fridgePriceValue = updatedRow.fridgePriceValue || 0;
    }
    const updatedRows = rows.map((row) =>
      row.id === newRow.id ? newRow : row,
    );
    setRows(updatedRows);
    dataProvider.update('product_price', {
      id: newRow.id,
      data: JSON.stringify(newRow),
    });
  };

  const handleFilterChange = async (updatedRow, value) => {
    updatedRow.product = record.id;
    if (updatedRow.dnsNamePriceActivated) {
      updatedRow.dnsName = value;
    } else if (updatedRow.paymentMethodPriceActivated) {
      updatedRow.paymentMethod = value;
    } else if (updatedRow.fridgePriceActivated) {
      updatedRow.fridge = value;
    }

    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? updatedRow : row,
    );
    setRows(updatedRows);
  };

  const saveFilterChange = async (updatedRow, value) => {
    dataProvider.update('product_price', {
      id: updatedRow.id,
      data: JSON.stringify(updatedRow),
    });
  };

  const handlePriceChange = async (updatedRow, value) => {
    updatedRow.product = record.id;

    if (updatedRow.dnsNamePriceActivated) {
      updatedRow.dnsNamePriceValue = parseFloat(value);
    } else if (updatedRow.paymentMethodPriceActivated) {
      updatedRow.paymentMethodPriceValue = parseFloat(value);
    } else if (updatedRow.fridgePriceActivated) {
      updatedRow.fridgePriceValue = parseFloat(value);
    }

    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? updatedRow : row,
    );

    setRows(updatedRows);
  };

  const savePriceChange = async (updatedRow, value) => {
    dataProvider.update('product_price', {
      id: updatedRow.id,
      data: JSON.stringify(updatedRow),
    });
  };

  const handleAddRow = async () => {
    const newRow = { ...newLine };
    delete newRow.id;
    delete newRow['@id'];
    delete newRow['@type'];
    try {
      const createdRow = await dataProvider.create('product_prices', {
        data: newRow,
      });
      setRows([...rows, createdRow.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRow = async (id) => {
    try {
      await dataProvider.delete('product_prices', { id });
      setRows(rows.filter((row) => row.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: 'category',
      headerName: translate('resources.products.fields.category'),
      width: 250,
      renderCell: (params) => {
        const currentValue = params.row.dnsNamePriceActivated
          ? categories[0].id
          : params.row.paymentMethodPriceActivated
          ? categories[1].id
          : params.row.fridgePriceActivated
          ? categories[2].id
          : '';
        return (
          <Select
            disabled={!editable}
            value={currentValue}
            onChange={(event) =>
              handleCategoryChange(params.row, event.target.value)
            }
            fullWidth
          >
            {categories.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: 'filter',
      headerName: translate('resources.products.fields.filter'),
      renderCell: (params) => {
        return (
          <ParamsField
            disabled={!editable}
            params={params}
            handleCategoryChange={handleCategoryChange}
            handleFilterChange={handleFilterChange}
            saveFilterChange={saveFilterChange}
            fridges={fridges}
            translate={translate}
          />
        );
      },
      width: 200,
    },
    {
      field: 'price',
      headerName: translate('resources.products.fields.priceVariation'),
      renderCell: (params) => {
        const currentValue = params.row.dnsNamePriceActivated
          ? params.row.dnsNamePriceValue
          : params.row.paymentMethodPriceActivated
          ? params.row.paymentMethodPriceValue
          : params.row.fridgePriceActivated
          ? params.row.fridgePriceValue
          : '';

        const safeValue = currentValue === null ? '' : currentValue;

        return (
          <TextField
            disabled={!editable}
            value={safeValue}
            type="number"
            InputProps={{
              startAdornment: safeValue > 0 ? '+' : null,
            }}
            onBlur={(event) => savePriceChange(params.row, event.target.value)}
            onChange={(event) => {
              handlePriceChange(params.row, event.target.value);
            }}
          />
        );
      },
      width: 200,
    },
    editable
      ? {
          field: 'actions',
          headerName: 'Actions',
          width: 200,
          renderCell: (params) => (
            <Button
              color="secondary"
              onClick={() => handleDeleteRow(params.row.id)}
              disabled={!editable}
            >
              {translate('resources.products.fields.delete')}
            </Button>
          ),
        }
      : {},
  ];

  return (
    (editable || rows.length > 0) && (
      <div style={{ height: 500, marginBottom: 70 }}>
        <label
          className="MuiFormLabel-root"
          style={{ width: '100%', display: 'block', marginBottom: 10 }}
        >
          {translate('resources.products.fields.gridTitle')} :
        </label>
        {editable && (
          <Button
            onClick={() => {
              if (rows.length < 0) {
                setDisplayTab(!displayTab);
              }
              handleAddRow();
            }}
            variant="contained"
            color="primary"
            disabled={!editable}
          >
            Ajouter des prix sous conditions
          </Button>
        )}

        {(displayTab || rows.length > 0 || !editable) && (
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter={true}
            style={{ marginTop: '1rem' }}
          />
        )}
      </div>
    )
  );
};

export default withTranslate(EditablePriceGrid);
