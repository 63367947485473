import React from 'react';
import {
  TextInput, required, ReferenceInput, AutocompleteInput, email, ImageInput, ImageField, NumberInput,
} from 'react-admin';
import PercentInput from '../common/PercentInput';
import BillingIntervalInput from './BillingIntervalInput';

function colorValidate(value) {
  if (!value) {
    return undefined;
  }

  if (typeof value !== 'string') {
    return 'Must be a String';
  }

  if (!value.match(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/)) {
    return 'Must be an hexadecimal color';
  }

  return undefined;
}

export default [
  <TextInput key="name" source="name" validate={required()} />,
  <TextInput key="description" source="description" multiline />,
  <TextInput key="slug" source="slug" validate={required()} />,
  <ImageInput key="logo" source="logo" accept="image/*">
    <ImageField source="src" title="title" />
  </ImageInput>,
  <TextInput key="color" source="color" validate={colorValidate} />,
  <PercentInput key="commissionRate" source="commissionRate" validate={required()} />,
  <BillingIntervalInput key="billingInterval" source="billingInterval" validate={required()} />,
  <TextInput key="iban" source="iban" validate={required()} />,
  <TextInput key="bic" source="bic" validate={required()} />,
  <TextInput key="businessIdentifier" source="businessIdentifier" parse={(value) => value || null} />,
  <TextInput key="streetLine1" source="streetLine1" />,
  <TextInput key="streetLine2" source="streetLine2" />,
  <TextInput key="postalCode" source="postalCode" />,
  <TextInput key="city" source="city" />,
  <TextInput key="region" source="region" />,
  <ReferenceInput
    filterToQuery={(searchText) => ({ name: searchText })}
    allowEmpty
    key="country"
    source="country"
    reference="countries"
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <TextInput key="phone1" source="phone1" />,
  <TextInput key="phone2" source="phone2" />,
  <TextInput key="fax" source="fax" />,
  <TextInput key="email" source="email" validate={email()} />,
  <TextInput key="web" source="web" />,
  <TextInput key="piElectroniqueScorePi" source="piElectroniqueScorePi" parse={(value) => value || null} />,
  <TextInput key="paygreenShopId" source="paygreenShopId" parse={(value) => value || null} />,
  <TextInput key="adyenAccountId" source="adyenAccountId" parse={(value) => value || null} />,
  <TextInput key="edenredMerchantId" source="edenredMerchantId" parse={(value) => value || null} />,
  <TextInput key="jesActivityCode" source="jesActivityCode" parse={(value) => value || null} />,
  <NumberInput key="mcc" source="mcc" />,
  <NumberInput key="annualProcessingVolume" source="annualProcessingVolume" format={(value) => value / 100} parse={(value) => value * 100} />,
  <NumberInput key="averageTransactionValue" source="averageTransactionValue" format={(value) => value / 100} parse={(value) => value * 100} />,
  <NumberInput key="highestTransactionValue" source="highestTransactionValue" format={(value) => value / 100} parse={(value) => value * 100} />,
];
