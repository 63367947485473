import React, { useCallback } from 'react';
import {
  FileInput, ReferenceManyField,
  Tab, translate as withTranslate, useCreate, useRefresh,
  useNotify, FileField,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import FridgeMediaList from '../../fridge_media/List';

const FridgeMediaForm = withTranslate((props) => {
  const { translate, record } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      create('fridge_media', {
        file,
        fridge: record.slug,
        extraInformation: { hasFileField: true },
      }, {
        returnPromise: true,
      }).then(() => {
        notify('ra.notification.created', { type: 'info' }, { smart_count: 1 }, false);
        refresh();
      }).catch((err) => {
        notify(err.message ? err.message : 'resources.fridge_media.upload.error', { type: 'error' });
      });
    });
  }, [create, notify, record, refresh]);

  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FileInput
            source="file"
            label={translate('resources.fridges.fields.medias')}
            accept="image/*, video/*"
            maxSize={100000000}
            options={{onDrop}}
            helperText={translate('resources.fridges.fields.medias_helper')}
          >
            <FileField source="url" title="title"/>
          </FileInput>
        </form>
      )}
    />
  );
});

const MediasTab = (props) => {
  const { translate, record, ...rest } = props;

  return (
    <Tab {...rest} record={record} label={translate('resources.fridges.tabs.medias')}>
      <FridgeMediaForm record={record} />

      <ReferenceManyField
        reference="fridge_media"
        target="fridge"
        source="originId"
        addLabel={false}
        sort={{ field: 'position', order: 'asc' }}
      >
        <FridgeMediaList />
      </ReferenceManyField>
    </Tab>
  );
};

MediasTab.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

MediasTab.defaultProps = {
  record: {
    id: null,
    name: null,
  },
};

export default withTranslate(MediasTab);
