import React from 'react';
import { RadioButtonGroupInput, TextField } from 'react-admin';
import PropTypes from 'prop-types';

const colors = {
  A: '#00bf00',
  B: '#73ff00',
  C: '#ffcc00',
  D: '#ff6600',
  E: '#ff1900',
};

const nutriScoreOptionRenderer = (choice) => (
  <span
    style={{ color: colors[choice.name] || 'inherit' }}
  >{`${choice.name}`}</span>
);

export const NutriScoreInput = (props) => (
  <RadioButtonGroupInput
    {...props}
    key="nutriScore"
    source="nutriScore"
    translateChoice={false}
    optionText={nutriScoreOptionRenderer}
    choices={[
      { id: 'A', name: 'A' },
      { id: 'B', name: 'B' },
      { id: 'C', name: 'C' },
      { id: 'D', name: 'D' },
      { id: 'E', name: 'E' },
      { id: '?', name: '?' },
    ]}
  />
);

export const NutriScoreField = (props) => {
  const { record } = props;
  return (
    <TextField
      style={{ color: colors[record.nutriScore] || 'inherit' }}
      key="nutriScore"
      source="nutriScore"
      {...props}
    />
  );
};

NutriScoreField.propTypes = {
  record: PropTypes.shape({
    nutriScore: PropTypes.string,
  }),
  addLabel: PropTypes.bool,
};

NutriScoreField.defaultProps = {
  record: null,
  addLabel: true,
};
