import * as React from 'react';
import {
  Datagrid,
  List,
  BulkDeleteButton,
  SimpleList,
  TextField,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import fields from './fields';
import FridgeMediaField from './FridgeMediaField';

const FridgeMediaBulkActionButtons = ({ basepath }) => (
  <>
    <BulkDeleteButton basepath={basepath} />
  </>
);

FridgeMediaBulkActionButtons.propTypes = {
  basepath: PropTypes.string,
};

FridgeMediaBulkActionButtons.defaultProps = {
  basepath: null,
};

const FridgeMediaList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      bulkActionButtons={<FridgeMediaBulkActionButtons />}
      exporter={false}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={<FridgeMediaField />}
          secondaryText={<TextField source="media.src" />}
          linkType="edit"
        />
      ) : (
        <Datagrid>{fields}</Datagrid>
      )}
    </List>
  );
};

export default FridgeMediaList;
