import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  ImageField,
  ImageInput,
  translate as withTranslate,
  FormDataConsumer,
  NumberInput,
  SelectInput,
} from 'react-admin';
import { get } from 'lodash';
import { useField } from 'react-final-form';
import { BlockPicker } from 'react-color';
import { ROLE_ADMIN } from '../providers/authProvider';
import { userOptionText } from '../user/fields';

const ColorInput = ({ source }) => {
  const {
    input: { onChange, value },
  } = useField(source);

  return (
    <BlockPicker
      color={value}
      onChangeComplete={(color) => onChange(color.hex)}
    />
  );
};

ColorInput.propTypes = {
  source: PropTypes.string.isRequired,
};

const FridgeEdit = (props) => {
  const { translate, permissions, ...rest } = props;
  const choices = [
    { id: 'nextgen', name: 'Nextgen' },
    { id: 'legacy', name: 'Legacy' },
  ];
  return (
    <Edit {...rest}>
      <SimpleForm redirect="show">
        <BooleanInput source="enabled" validate={required()} />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <>
            <ReferenceInput
              source="restaurateur"
              reference="restaurateurs"
              filterToQuery={(searchText) => ({ name: searchText })}
              validate={required()}
              format={(v) => v['@id'] || v}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput source="posTerminalId" />
            <BooleanInput source="scanOnTap" validate={required()} />
          </>
        )}
        <TextInput source="name" validate={required()} />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput source="slug" validate={required()} />
        )}
        <ImageInput
          source="logo"
          label="resources.fridges.fields.logo"
          accept="image/*"
          maxSize={8000000}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="customThemePrimaryColor" validate={required()} />
        <FormDataConsumer>
          {({ formData }) =>
            formData.customThemePrimaryColor && (
              <ColorInput
                source="themePrimaryColor"
                label={translate(
                  'resources.fridges.fields.customThemePrimaryColor',
                )}
              />
            )
          }
        </FormDataConsumer>

        <BooleanInput
          source="customThemeHeaderBackgroundColor"
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.customThemeHeaderBackgroundColor && (
              <ColorInput
                source="themeHeaderBackgroundColor"
                label={translate(
                  'resources.fridges.fields.customThemeHeaderBackgroundColor',
                )}
              />
            )
          }
        </FormDataConsumer>

        <BooleanInput
          source="customThemeBodyBackgroundColor"
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.customThemeBodyBackgroundColor && (
              <ColorInput
                source="themeBodyBackgroundColor"
                label={translate(
                  'resources.fridges.fields.customThemeBodyBackgroundColor',
                )}
              />
            )
          }
        </FormDataConsumer>

        <BooleanInput
          source="themeEnableHomePaymentMethodsHelp"
          validate={required()}
        />

        <BooleanInput source="hideQRCode" validate={required()} />

        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="piElectroniqueScorePi"
            parse={(value) => value || null}
          />
        )}

        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="piElectroniqueVendingId"
            parse={(value) => value || null}
          />
        )}

        <TextInput
          source="reductionStart"
          label={translate('resources.fridges.fields.reductionStartHour', 1)}
          type="time"
        />
        <TextInput
          source="reductionEnd"
          label={translate('resources.fridges.fields.reductionEndHour', 1)}
          type="time"
        />
        <NumberInput
          source="hoursBeforeNoWasteReduction"
          label={translate('resources.fridges.fields.noWasteOfferDelay', 1)}
        />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="apiNextGenToken"
            label={translate('resources.fridges.fields.apiToken', 1)}
          />
        )}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="apiNextGenFridgeUuid"
            label={translate('resources.fridges.fields.apiNextGenFridgeUuid', 1)}
          />
        )}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <SelectInput source="apiType" choices={choices} />
        )}

        <NumberInput
          max="365"
          min="0"
          source="jesAvailableCreditNeeded"
          label={translate(
            'resources.fridges.fields.jesAvailableCreditNeeded',
            1,
          )}
        />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <BooleanInput source="allowApiPrivateToken" validate={required()} />
        )}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <BooleanInput source="hasPrivilegedAccess" validate={required()} />
        )}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <FormDataConsumer>
            {({ formData }) =>
              formData.hasPrivilegedAccess && (
                <>
                  <ReferenceInput
                    key="privilegedUser"
                    source="privilegedUser"
                    reference="users"
                    filterToQuery={(searchText) => ({ email: searchText })}
                    allowEmpty
                    emptyValue={null}
                    format={(v) => v['@id'] || v}
                  >
                    <AutocompleteInput
                      optionText={(user) =>
                        user && userOptionText(user, translate)
                      }
                      optionValue="id"
                    />
                  </ReferenceInput>
                  <TextInput
                    source="privilegedInventoryWebhook"
                    label={translate(
                      'resources.fridges.fields.privilegedInventoryWebhook',
                      1,
                    )}
                  />
                </>
              )
            }
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Edit>
  );
};

FridgeEdit.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

FridgeEdit.defaultProps = {
  permissions: null,
};

export default withTranslate(FridgeEdit);
