import * as PropTypes from 'prop-types';
import React from 'react';
import {
  Show,
  TabbedShowLayout,
  ReferenceField,
  TextField,
  ReferenceManyField,
  Datagrid,
  NumberField,
  Tab,
  translate as withTranslate,
} from 'react-admin';
import fields from './fields';
import { EmailOrNfcField } from '../user/fields';
import VideoField from './VideoField';

const OrderShow = ({ translate, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label={translate('resources.orders.tabs.detail')}>
        <ReferenceManyField
          reference="order_lines"
          target="targetOrder"
          addLabel={false}
          filter={{ pagination: 'false' }}
          pagination={null}
        >
          <Datagrid>
            <TextField source="productName" />
            <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="quantity" />
            <NumberField source="tax" options={{ style: 'currency', currency: 'EUR' }} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label={translate('resources.orders.tabs.general')}>
        <ReferenceField source="client['@id']" label="Client" reference="users" link="show">
          <EmailOrNfcField />
        </ReferenceField>
        {fields(false)}
        <VideoField />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

OrderShow.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(OrderShow);
